/*
    Flaticon icon font: Flaticon
    Creation date: 11/04/2019 05:01
    */

@font-face {
  font-family: 'Flaticon';
  src: url('./../../../assets/fonts/Flaticon.eot');
  src:
    url('./../../../assets/fonts/Flaticon.eot?#iefix') format('embedded-opentype'),
    url('./../../../assets/fonts/Flaticon.woff2') format('woff2'),
    url('./../../../assets/fonts/Flaticon.woff') format('woff'),
    url('./../../../assets/fonts/Flaticon.ttf') format('truetype'),
    url('./../../../assets/fonts/Flaticon.svg#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Flaticon';
    src: url('./../../../assets/fonts/Flaticon.svg#Flaticon') format('svg');
  }
}
.fi:before {
  display: inline-block;
  font-family: 'Flaticon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.flaticon-shield:before {
  content: '\f100';
}
.flaticon-layout:before {
  content: '\f101';
}
.flaticon-layers:before {
  content: '\f102';
}
.flaticon-cube:before {
  content: '\f103';
}
.flaticon-compass:before {
  content: '\f104';
}
.flaticon-thunderbolt:before {
  content: '\f105';
}
.flaticon-gift:before {
  content: '\f106';
}
.flaticon-shapes-and-symbols:before {
  content: '\f107';
}
.flaticon-feather:before {
  content: '\f108';
}
.flaticon-menu:before {
  content: '\f109';
}
.flaticon-abc:before {
  content: '\f10a';
}
.flaticon-alarm:before {
  content: '\f10b';
}
.flaticon-locker:before {
  content: '\f10c';
}
.flaticon-menu-1:before {
  content: '\f10d';
}
.flaticon-crop:before {
  content: '\f10e';
}
.flaticon-mail:before {
  content: '\f10f';
}
.flaticon-diamond:before {
  content: '\f110';
}
.flaticon-edit:before {
  content: '\f111';
}
.flaticon-gear:before {
  content: '\f112';
}
.flaticon-video:before {
  content: '\f113';
}
.flaticon-clipboard:before {
  content: '\f114';
}
.flaticon-search:before {
  content: '\f115';
}
.flaticon-data-storage:before {
  content: '\f116';
}
.flaticon-table:before {
  content: '\f117';
}
.flaticon-pie-chart:before {
  content: '\f118';
}
.flaticon-map:before {
  content: '\f119';
}
.flaticon-calendar:before {
  content: '\f11a';
}
.flaticon-mixer:before {
  content: '\f11b';
}
.flaticon-chat:before {
  content: '\f11c';
}
.flaticon-lock:before {
  content: '\f11d';
}
.flaticon-photo:before {
  content: '\f11e';
}
.flaticon-start-up:before {
  content: '\f11f';
}
.flaticon-test:before {
  content: '\f120';
}
.flaticon-study:before {
  content: '\f121';
}
.flaticon-information:before {
  content: '\f122';
}
.flaticon-support:before {
  content: '\f123';
}
.flaticon-help:before {
  content: '\f124';
}
.flaticon-business-and-finance:before {
  content: '\f125';
}
.flaticon-cloud:before {
  content: '\f126';
}
.flaticon-grid:before {
  content: '\f127';
}
.flaticon-text:before {
  content: '\f128';
}
.flaticon-draft:before {
  content: '\f129';
}
.flaticon-writing:before {
  content: '\f12a';
}
.flaticon-flag:before {
  content: '\f12b';
}
.flaticon-waiting:before {
  content: '\f12c';
}
.flaticon-questions:before {
  content: '\f12d';
}

$font-Flaticon-shield: '\f100';
$font-Flaticon-layout: '\f101';
$font-Flaticon-layers: '\f102';
$font-Flaticon-cube: '\f103';
$font-Flaticon-compass: '\f104';
$font-Flaticon-thunderbolt: '\f105';
$font-Flaticon-gift: '\f106';
$font-Flaticon-shapes-and-symbols: '\f107';
$font-Flaticon-feather: '\f108';
$font-Flaticon-menu: '\f109';
$font-Flaticon-abc: '\f10a';
$font-Flaticon-alarm: '\f10b';
$font-Flaticon-locker: '\f10c';
$font-Flaticon-menu-1: '\f10d';
$font-Flaticon-crop: '\f10e';
$font-Flaticon-mail: '\f10f';
$font-Flaticon-diamond: '\f110';
$font-Flaticon-edit: '\f111';
$font-Flaticon-gear: '\f112';
$font-Flaticon-video: '\f113';
$font-Flaticon-clipboard: '\f114';
$font-Flaticon-search: '\f115';
$font-Flaticon-data-storage: '\f116';
$font-Flaticon-table: '\f117';
$font-Flaticon-pie-chart: '\f118';
$font-Flaticon-map: '\f119';
$font-Flaticon-calendar: '\f11a';
$font-Flaticon-mixer: '\f11b';
$font-Flaticon-chat: '\f11c';
$font-Flaticon-lock: '\f11d';
$font-Flaticon-photo: '\f11e';
$font-Flaticon-start-up: '\f11f';
$font-Flaticon-test: '\f120';
$font-Flaticon-study: '\f121';
$font-Flaticon-information: '\f122';
$font-Flaticon-support: '\f123';
$font-Flaticon-help: '\f124';
$font-Flaticon-business-and-finance: '\f125';
$font-Flaticon-cloud: '\f126';
$font-Flaticon-grid: '\f127';
$font-Flaticon-text: '\f128';
$font-Flaticon-draft: '\f129';
$font-Flaticon-writing: '\f12a';
$font-Flaticon-flag: '\f12b';
$font-Flaticon-waiting: '\f12c';
$font-Flaticon-questions: '\f12d';
