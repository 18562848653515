/**  =====================
      Generic-class css start
========================== **/
/*====== Padding , Margin css starts ======*/
$i: 0;

@while $i<=50 {
  .p {
    &-#{$i} {
      padding: #{$i}px;
    }

    &-t-#{$i} {
      padding-top: #{$i}px;
    }

    &-b-#{$i} {
      padding-bottom: #{$i}px;
    }

    &-l-#{$i} {
      padding-left: #{$i}px;
    }

    &-r-#{$i} {
      padding-right: #{$i}px;
    }
  }

  .m {
    &-#{$i} {
      margin: #{$i}px;
    }

    &-t-#{$i} {
      margin-top: #{$i}px;
    }

    &-b-#{$i} {
      margin-bottom: #{$i}px;
    }

    &-l-#{$i} {
      margin-left: #{$i}px;
    }

    &-r-#{$i} {
      margin-right: #{$i}px;
    }
  }

  $i: $i + 5;
}

.mt-n5 {
  margin-top: -3rem !important;
}

/*====== Padding , Margin css ends ======*/

/*====== text-color, background & border css starts ======*/
@each $value in $color-name {
  $i: index($color-name, $value);

  .bg-#{'' + $value} {
    background: linear-gradient(45deg, nth($color-color, $i), lighten(nth($color-color, $i), 10%));
  }

  .text-#{'' + $value} {
    color: nth($color-color, $i);
  }

  .b-#{'' + $value} {
    border: 1px solid nth($color-color, $i);
  }
}

/*====== text-color, background & border css ends ======*/
/*====== [ overrides bootstrap color ]text-color, background & border css starts ======*/
@each $value in $color-bt-name {
  $i: index($color-bt-name, $value);

  .bg-#{'' + $value} {
    background: linear-gradient(45deg, nth($color-bt-color, $i), lighten(nth($color-bt-color, $i), 10%)) !important;
  }

  .text-#{'' + $value} {
    color: nth($color-bt-color, $i) !important;
  }

  .b-#{'' + $value} {
    border: 1px solid nth($color-bt-color, $i) !important;
  }

  .text-h-#{'' + $value}:hover {
    color: nth($color-bt-color, $i) !important;
  }
}

/*====== [ overrides bootstrap color ]text-color, background & border css ends ======*/
/*====== border color css starts ======*/
@each $value in $color-bt-name {
  $i: index($color-bt-name, $value);

  .b-#{'' + $value} {
    border: 1px solid nth($color-bt-color, $i);
  }
}

/*====== border color css ends ======*/

/*====== Card top border css starts ======*/
@each $value in $color-name {
  $i: index($color-name, $value);

  .card-border-#{'' + $value} {
    border-top: 4px solid nth($color-color, $i);
  }
}

/*====== Card top border ends ======*/
/*====== Font-size css starts ======*/
$i: 10;

@while $i<=80 {
  .f-#{$i} {
    font-size: #{$i}px;
  }

  $i: $i + 2;
}

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
$i: 100;

@while $i<=900 {
  .f-w-#{$i} {
    font-weight: #{$i};
  }

  $i: $i + 100;
}

/*====== Font-weight css ends ======*/

/*====== width, Height css starts ======*/
$i: 20;

@while $i<=150 {
  .wid-#{$i} {
    width: #{$i}px;
  }

  $i: $i + 5;
}

/*====== width, Height css ends ======*/
