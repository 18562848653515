/**  =====================
      Chatting css start
==========================  **/

.header-chat,
.header-user-list {
  height: 100%;
  width: 300px;
  position: fixed;
  top: 0;
  right: -300px;
  border-radius: 0;
  z-index: 1030;
  background-color: #fff;
  transition: all 0.3s ease-in-out;

  .main-friend-cont {
    height: calc(100vh - 60px);
    position: relative;
  }
  .h-list-header + .h-list-body > .main-friend-cont {
    height: calc(100vh - 130px);
    position: relative;
  }

  .h-list-header {
    padding: 15px;
    border-bottom: 1px solid #f1f1f1;
  }

  &.open {
    box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
    right: 0;
  }
}

.header-user-list {
  .nav-tabs {
    border-bottom: 1px solid #f1f1f1;
    .nav-item {
      width: calc(100% / 3);
      text-align: center;

      a {
        padding: 20px 0;
        position: relative;
        background-color: transparent;
        background-image: linear-gradient($primary-color, $primary-color);
        background-size: 0% 2px;
        background-position: bottom;
        background-repeat: no-repeat;
        transition: all 0.3s ease-in-out;
        &.active {
          box-shadow: none;
          background-size: 100% 2px;
          color: $primary-color;
        }
      }
    }
  }

  .tab-content {
    padding: 0;
    box-shadow: none;
  }
}

.header-user-list {
  &.open {
    .h-close-text {
      position: absolute;
      top: 35px;
      left: -30px;

      i {
        position: absolute;
        top: 55px;
        left: 7px;
        font-size: 20px;
        z-index: 1003;
        color: #fff;
      }

      &:after {
        content: '';
        z-index: 1001;
        position: absolute;
        top: 45px;
        left: -2px;
        background: $primary-color;
        width: 32px;
        height: 40px;
        box-shadow: -5px 2px 15px -4px rgba(69, 90, 100, 0.5);
        border-radius: 20px 0 0 20px;
      }
    }

    &.msg-open {
      &:after {
        color: transparentize($primary-color, 0.9);
      }
    }
  }
}

.h-list-header {
  .form-control {
    &:active,
    &:focus,
    &:hover {
      box-shadow: none;
      outline: none;
    }
  }
}

.h-list-body {
  position: relative;

  .userlist-box {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 20px;
      width: calc(100% - 40px);
      height: 1px;
      background: #f3f4f9;
    }

    &.active {
      background: lighten($primary-color, 45%);
    }

    .media-left {
      padding-right: 10px;
    }

    .media-object {
      width: 50px;
      display: inline-block;
    }

    .chat-header {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;

      small {
        margin-top: 5px;
        font-size: 90%;
      }
    }

    .live-status {
      height: 25px;
      width: 25px;
      position: absolute;
      top: 35px;
      right: 20px;
      border-radius: 100%;
      color: #fff;
      padding: 2px 0;
      text-align: center;
      background: $primary-color;
    }
  }
}

.header-chat {
  .h-list-header {
    text-align: center;
    position: relative;

    h6 {
      margin: 5px 0;
    }

    .h-back-user-list {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      width: 40px;
      justify-content: center;
      font-size: 20px;
    }
  }

  .main-chat-cont {
    height: calc(100vh - 166px);
  }

  .h-list-body {
    height: 100%;
    background: lighten($primary-color, 41%);
  }

  .h-list-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    right: 0;
    padding: 20px 15px;
    z-index: 10;
    background: lighten($primary-color, 41%);

    .input-group {
      background: #fff;
      border: none;
      display: inline-flex;
      box-shadow: 0 5px 15px 0 rgba(62, 57, 107, 0.15);
      padding: 7px;
      border-radius: 20px;
      width: calc(100% - 40px);

      .form-control,
      .input-group-text {
        background: transparent;
        border: none;
        border-radius: 0;
        padding: 0;
      }

      .btn-send,
      .form-control {
        &:active,
        &:focus,
        &:hover {
          outline: none;
          box-shadow: none;
        }
      }

      .btn-attach {
        border-radius: 50%;
        padding: 5px;
        margin-right: 5px;

        > i {
          margin-right: 0;
        }
      }

      .btn-send {
        border-radius: 50%;
        padding: 10px;
        margin-left: 5px;
        position: absolute;
        right: -45px;
        top: 2px;
        z-index: 99;

        i {
          margin-right: 0;
        }

        .input-group-text {
          color: #fff;
        }
      }

      .form-control {
        transition: all 0.3s ease-in-out;
        width: 0;
      }
    }
  }
}

.h-list-body {
  .chat-messages {
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;

    .photo-table {
      padding-right: 15px;

      img {
        display: inline-block;
        width: 45px;
        margin-bottom: 5px;
      }
    }

    .chat-menu-content,
    .chat-menu-reply {
      > div {
        position: relative;
        overflow: visible;
        display: inline-block;

        .chat-cont {
          padding: 8px 20px;
          display: inline-block;
        }
      }

      .chat-time {
        margin: 5px 24px 0 0;
      }
    }

    .chat-menu-reply {
      text-align: right;

      > div {
        margin-top: 10px;

        p {
          background: #fff;
          border-radius: 3px;
          margin-bottom: 4px;
          margin-right: 25px;
          box-shadow: 0 5px 15px 0 rgba(62, 57, 107, 0.15);
        }

        &:before {
          content: '';
          z-index: 1001;

          transform: rotate(-45deg);
          border: 6px solid transparent;
          border-right-color: #fff;
          border-bottom-color: #fff;
          position: absolute;
          bottom: 16px;
          right: 19px;
          box-shadow: 1px 5px 10px -3px rgba(62, 57, 107, 0.15);
        }
      }
    }

    .chat-menu-content {
      > div {
        margin-top: 10px;

        p {
          background: $primary-color;
          color: #fff;
          border-radius: 3px;
          margin-bottom: 4px;
          box-shadow: 0 5px 15px 0 rgba(62, 57, 107, 0.15);

          &:first-child {
            // border-top-left-radius: 0;
          }
        }

        &:before {
          content: '';
          z-index: 1001;
          transform: rotate(-45deg);
          border: 6px solid transparent;
          border-left-color: $primary-color;
          border-top-color: $primary-color;
          position: absolute;
          top: 12px;
          left: -6px;
          box-shadow: -4px 0 8px -5px rgba(62, 57, 107, 0.3);
        }
      }
    }
  }
}
/* massage page start */

.msg-card {
  .msg-user-list {
    height: calc(100vh - 300px);
  }

  .msg-user-chat {
    background: lighten($primary-color, 41%);
    height: calc(100vh - 330px);
    padding: 25px 5px;
  }

  .msg-block {
    > .row {
      > div {
        &:before {
          content: '';
          width: 1px;
          height: 100%;
          background: #f1f1f1;
          position: absolute;
          top: 0;
        }

        &:first-child {
          &:before {
            right: 0;
          }
        }

        &:last-child {
          &:before {
            left: -1px;
          }
        }
      }
    }
  }
}

.main-friend-chat {
  padding-bottom: 15px;
}
@media screen and (max-width: 991px) {
  .msg-card .msg-block > .row > div:before {
    background: none;
  }
}
/* massage page end */
/**====== Chat css end ======**/
